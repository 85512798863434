<!--
 * @Author: ChaiHongJun
 * @Date: 2021-06-02 14:23:08
 * @LastEditors: ChaiHongJun
 * @LastEditTime: 2021-06-17 15:18:36
 * @version: 
 * @Description: 联系我们
-->
<template>
  <section class="container">
    <div class="content-wrap single">
      <div class="content">
        <a-skeleton
          :loading="loadingContent"
          :active="loadingContent"
          :paragraph="{ rows: 10 }"
        >
          <article class="article-content" v-html="content"></article>
        </a-skeleton>
      </div>
    </div>
    <div class="sidebar">
      <a-skeleton
        :loading="loadingSticky"
        :active="loadingSticky"
        :paragraph="{ rows: 5 }"
      >
        <Sidebar siderName="置顶推荐" :dataList="Sticky"></Sidebar
      ></a-skeleton>

      <a-skeleton
        :loading="loadingRand"
        :active="loadingRand"
        :paragraph="{ rows: 5 }"
      >
        <Sidebar siderName="随机推荐" :dataList="Rand"></Sidebar
      ></a-skeleton>
    </div>
  </section>
</template>
<script>
import Sidebar from "@/components/common/sidebar/Index.vue";
import { getStickyList, getRandList, getSingle } from "@/request/index.js";
import { Message } from "element-ui";
export default {
  name: "About",
  components: {
    Sidebar,
  },
  data() {
    return {
      content: "",
      Sticky: [],
      Rand: [],
      loadingContent: true,
      loadingSticky: true,
      loadingRand: true,
      cateIDsidebar: 0,
      limit: 4,
    };
  },

  watch: {
    $route: "reloadPage",
  },

  methods: {
    reloadPage() {
      console.log("路由变化了？");
    },
  },

  created() {
    // 加载主要内容
    this.loadingContent = true;
    getSingle(15)
      .then((res) => {
        this.content = res.data.content;
        this.loadingContent = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingContent created" + err);
        this.loadingContent = false;
      });

    //加载置顶推荐
    this.loadingSticky = true;
    getStickyList(this.cateIDsidebar, this.limit / 2, 0)
      .then((res) => {
        this.Sticky = res.data;
        this.loadingSticky = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingSticky created" + err);
        this.loadingSticky = false;
      });
    /* 加载侧栏 随机数据*/
    this.loadingRand = true;
    getRandList(this.cateIDsidebar, this.limit / 2, 0)
      .then((res) => {
        this.Rand = res.data;
        this.loadingRand = false;
      })
      .catch((err) => {
        console.log("err " + err);
        Message.error("loadingRand created" + err);
        this.loadingRand = false;
      });
  },
};
</script>
<style>
</style>